<template>
  <div>
    <b-card no-body v-if="isLoading" style="height: 420px !important">
      <div class="d-flex justify-content-center align-items-center h-100">
        <b-spinner label="Loading..." />
      </div>
    </b-card>
    <b-card no-body v-else-if="errorsForm.length > 0"  class="px-3 py-2 mb-0" style="height: 420px !important">
      <b-card-title>
        <h3>Importar órdenes desde Excel</h3>
      </b-card-title>
      <b-card-body style="height: 200px !important">
        <div class="mt-2">
          <p>Ha ocurrido un error al procesar las ordenes.</p>
          <br />
          <p>Errores encontrados:</p>
          <br />
          <div style="max-height: 175px; overflow-y: auto; mt-1">
            <ul>
              <li v-for="(error, index) in errorsForm" :key="index">
                {{ error }}
              </li>
            </ul>
          </div>
        </div>
        <button
          class="btn btn-sm button-tertiary mr-1 mb-1 mt-2"
          @click="() => errorsForm = []"
        >
          <span style="font-size: 15px"> Continuar </span>
        </button>
      </b-card-body>
    </b-card>
    <b-card no-body v-else class="px-3 py-2 mb-0" style="height: 420px !important">
      <b-card-title>
        <h3>Importar órdenes desde Excel</h3>
      </b-card-title>
      <b-card-body style="height: 200px !important">
        <b-form @submit="onSubmit">
          <b-form-group>
            <b-form-file
              v-model="form.file"
              accept=".xls, .xlsx, .csv"
              ref="file"
            >
            </b-form-file>
          </b-form-group>
          <b-button
            ref="submitButton"
            style="font-size: 1rem !important"
            class="mr-1 btn-sm"
            type="submit"
            :disabled="!form.file"
          >
            <feather-icon size="15" icon="PlusIcon" class="mr-1" />
            <span style="font-size: 15px">Guardar</span>
          </b-button>
          <div class="mt-2">
            <a :href="urlFile" target="_blank">
              <span style="font-size: 15px">Descargar Plantilla</span>
            </a>
          </div>
        </b-form>
        <div class="mt-2">
          <p>
            <b>Importante:</b> 
            <br>
            <ul>
              <li>
                Si el envio es a un <b>PUNTO DE RETIRO</b>, no agregar datos a los campos <i>provincia, localidad, calle, altura, piso, departamento y codigo postal</i>.
              </li>
              <li>
                Si el envio es a <b>DOMICILIO</b>, no agregar datos al campo <i>punto</i>.
              </li>
              <li>
                El <b>Número de orden</b> es el número que va a relacionar la orden de Enviamelo con la de TiendaNube. Puedes utilizar el Número de Orden provisto por TiendaNube.
              </li>
            </ul>
            
          </p>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import OperationService from "@/services/OperationService";
import * as XLSX from "xlsx";

export default {
  name: "ImportOrderFromExcel",
  props: {
    onClose: Object,
    onSave: Object,
  },
  data() {
    return {
      isLoading: false,
      isErrorForm: false,
      errorsForm: [],
      fullPage: true,
      form: {
        file: null,
      },
    };
  },
  methods: {
    /**
     *
     */
    async onSubmit(event) {
      event.preventDefault();

      this.isLoading = true;
      this.errorsForm = [];

      const file = this.form.file;
      const reader = new FileReader();

      const readFileXLSX = async (sheet) => {
        sheet['!ref'] = "A1:AW100000";
        return XLSX.utils.sheet_to_json(sheet);
      };

      reader.onload = (e) => {
        try {
          const result = e.target.result;
          const workbook = XLSX.read(result, { type: "binary" });
          const wsname = workbook.SheetNames[0];
          readFileXLSX(workbook.Sheets[wsname]).then((ws) => {
            const wsData = this.newSctructureKeysPayload(ws).map((item) => {
              const retirement_item = {
                province: item.province,
                location: item.location,
                street: item.street,
                height: item.height,
                floor: item.floor,
                departament: item.departament,
                postal_code: item.postal_code,
              };
              return {
                declared_value: item.declared_value,
                dimensions: item.dimensions,
                external_id: item.external_id,
                is_secured: item.is_secured,
                note: item.note_1,
                product: item.product,
                quantity_packages: item.quantity_packages,
                recipient_email: item.recipient_email,
                recipient_last_name: item.recipient_last_name,
                recipient_name: item.recipient_name,
                recipient_phone: item.recipient_phone,
                retirement: item.retirement,
                retirement_point_id:
                  item.retirement === "client"
                    ? null
                    : item.retirement_point_id,
                unit: "KG",
                weight: item.weight,
                retirement_item:
                  item.retirement === "client" ? retirement_item : null,
                customer_id: item.customer_id,
                postal_code: item.retirement === "client" ? null : item.postal_code
              };
            });
            this.saveOrders(wsData);
          });
        } catch (error) {
          this.isLoading = false;
          this.$toast(
            {
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                text: `${error}`,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            },
            {
              timeout: 10000,
            }
          );
        }
      };
      reader.readAsBinaryString(file);
    },
    /**
     *
     */
    async saveOrders(dataOrders) {
      try {
        const { data, status } = await OperationService.importOrdersFromExcel(
          dataOrders
        );

        if (status === 200) {
          this.form.file = null;
          this.isLoading = false;
          this.$swal.queue([
            {
              title: "Operacion Exitosa",
              text: "Espere unos minutos mientras las operaciones son procesadas.",
              timer: 3000,
            },
          ]);

          const interval = setInterval(async () => {
            const { data: dataStatus } =
              await OperationService.getStatusOperationExcelUser(data.id);
            const { status } = dataStatus;

            if (status === "PROCESADO") {
              this.$swal.queue([
                {
                  title: "Operacion Exitosa",
                  text: "Órdenes registradas con éxito",
                  timer: 3000,
                },
              ]);
              clearInterval(interval);
              this.onSave.handler();
              this.$emit("close");
            }
          }, 2000);
        }
      } catch (error) {
        this.isLoading = false;
        const { response } = error;
        if (response.status === 422 || response.status === 500) {
          const keys = Object.keys(response.data.errors);
          keys.forEach((key) => {
            response.data.errors[key].forEach((error) => {
              this.errorsForm.push(error);
              /* Anterior mensaje de error */
             /*  this.$toast(
                {
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Error`,
                    text: `${error}`,
                    icon: "AlertOctagonIcon",
                    variant: "danger",
                  },
                },
                {
                  timeout: 10000,
                }
              ); */
            });
          });
        } else {
          this.$toast(
            {
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                text: `Error al tratar de procesar`,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            },
            {
              timeout: 10000,
            }
          );
        }
      }
    },

    /**
     *
     */
    newSctructureKeysPayload(data) {
      return data.map(
        ({
          retiro,
          seguro,
          valor_declarado,
          cantidad_de_paquetes,
          provincia,
          localidad,
          calle,
          altura,
          piso,
          departamento,
          codigo_postal,
          peso,
          nombre_del_destinatario,
          email_del_destinatario,
          apellido_del_destinatario,
          producto,
          alturaxanchoxlargo,
          telefono_del_destinatario,
          nota,
          numero_de_orden,
          punto,
          customer_id,
        }) => ({
          retirement: retiro === "Domicilio" ? 'client' : retiro === "Punto de retiro" ? 'point' : null,
          is_secured: (seguro && seguro.toLowerCase() === "si") ? true : false,
          declared_value: valor_declarado,
          quantity_packages: cantidad_de_paquetes,
          province: provincia,
          location: localidad,
          street: calle,
          height: altura,
          floor: piso,
          departament: departamento,
          postal_code: codigo_postal,
          weight: peso,
          recipient_name: nombre_del_destinatario,
          recipient_email: email_del_destinatario,
          recipient_last_name: apellido_del_destinatario,
          product: producto,
          dimensions: alturaxanchoxlargo,
          recipient_phone: "" + telefono_del_destinatario,
          note_1: nota,
          external_id: numero_de_orden,
          retirement_point_id:
            retiro !== "Domicilio" && punto ? punto.split("#")[1] : null,
          customer_id,
        })
      );
    },
  },
  computed: {
    urlFile: function () {
      return `${process.env.VUE_APP_API}/excel-download`;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-body {
  height: 200px;
  display: block;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
